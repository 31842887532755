.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: black;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: green;
  transform-origin: bottom right;
  transition: transform 0.4s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
